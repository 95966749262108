@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as *

.bs-w-card
  color: get-color(bs-b)
  margin-block: 0
  margin-inline: 0

  svg
    block-size: rem-fn(62)
    inline-size: rem-fn(62)
    margin-block-end: rem-fn(30)

  @include from($breakpoint-md-min)
    padding-inline: rem-fn(20)
    text-align: center
